import { createAction, props } from "@ngrx/store";
import { DiscussionCategory } from "../../domain/entities/discussion-category.entity";
import {
  CreateDiscussionResponseDTO,
  DiscussionDTO
} from "../../application/dtos/discussion.dto";
import { Discussion } from "../../domain/entities/discussion.entity";

// Load Discussion Categories
export const loadDiscussionCategories = createAction(
  "[Load Discussion Categories] Load Discussion Categories"
);
export const loadDiscussionCategoriesSuccess = createAction(
  "[Load Discussion Categories] Load Discussion Categories Success",
  props<{ categories: DiscussionCategory[] }>()
);
export const loadDiscussionCategoriesFailure = createAction(
  "[Load Discussion Categories] Load Discussion Categories Failure",
  props<{ error: any }>()
);

// Create Discussion
export const createDiscussion = createAction(
  "[Create Discussion] Create Discussion",
  props<{ discussion: DiscussionDTO }>()
);
export const createDiscussionSuccess = createAction(
  "[Create Discussion] Create Discussion Success",
  props<{ discussion: CreateDiscussionResponseDTO }>()
);
export const createDiscussionFailure = createAction(
  "[Create Discussion] Create Discussion Failure",
  props<{ error: any }>()
);

// Load Discussions
export const loadDiscussions = createAction("[Load Discussions] Load Discussions");
export const loadDiscussionsSuccess = createAction(
  "[Load Discussions] Load Discussions Success",
  props<{ discussions: Discussion[] }>()
);
export const loadDiscussionsFailure = createAction(
  "[Load Discussions] Load Discussions Failure",
  props<{ error: any }>()
);

// Activate Discussion
export const activateDiscussion = createAction(
  "[Activate Discussion] Activate Discussion",
  props<{ discussionId: string }>()
);
export const activateDiscussionSuccess = createAction(
  "[Activate Discussion] Activate Discussion Success"
);
export const activateDiscussionFailure = createAction(
  "[Activate Discussion] Activate Discussion Failure",
  props<{ error: any }>()
);

// Deactivate Discussion
export const deactivateDiscussion = createAction(
  "[Deactivate Discussion] Deactivate Discussion",
  props<{ discussionId: string }>()
);
export const deactivateDiscussionSuccess = createAction(
  "[Deactivate Discussion] Deactivate Discussion Success"
);
export const deactivateDiscussionFailure = createAction(
  "[Deactivate Discussion] Deactivate Discussion Failure",
  props<{ error: any }>()
);

// Delete Discussion
export const deleteDiscussion = createAction(
  "[Delete Discussion] Delete Discussion",
  props<{ discussionId: string }>()
);
export const deleteDiscussionSuccess = createAction(
  "[Delete Discussion] Delete Discussion Success",
  props<{ discussionId: string }>()
);
export const deleteDiscussionFailure = createAction(
  "[Delete Discussion] Delete Discussion Failure",
  props<{ error: any }>()
);