import { createAction, props } from "@ngrx/store";
import { Message } from "../../domain/entities/message.entity";

// Load Messages
export const loadMessages = createAction(
  "[Message] Load Messages",
  props<{ discussionId: string }>()
);
export const loadMessagesSuccess = createAction(
  "[Message] Load Messages Success",
  props<{ messages: Message[] }>()
);
export const loadMessagesFailure = createAction(
  "[Message] Load Messages Failure",
  props<{ error: any }>()
);